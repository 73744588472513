<template>
  <div>
    <div id="chat-content" class="chat-content" ref="chatContent">
      <div class="load_more" v-show="is_load_more" @click="load_more">
        加载更多...
      </div>

      <div class="other-msg">
        <div class="service_title">
          羽林数据-售前顾问
        </div>
        <span>您好,有什么可以帮您的吗?</span>
      </div>

      <div v-show="messageList.length" :class="item.flow === 'out' ? 'self-msg' : 'other-msg'" v-for="(item,index) in messageList" :key="index">
        <div class="service_title" v-show="item.flow !== 'out'">
          羽林数据客服
        </div>
        <span>{{item.payload.text}}</span>
      </div>

    </div>
    <div class="chat_input">
      <textarea id="chat_area" cols="48" rows="6" v-model="content"></textarea>
      <div id="msgSubmit" class="chatt_send_area" @click="sendMsg">
        发送
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import TIM from '@/utils/tim-js.js'

export default {
  name: 'Chat',
  data() {
    return {
      is_load_more: true,
      // 保存tim实例
      tim: null,
      serviceId: undefined,
      nextReqMessageID: undefined,
      // 聊天框文字
      content: '',
      // 历史聊天记录
      messageList: []
    }
  },
  mounted() {
    let options = {
      SDKAppID: 1400555298 // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
    }
    // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
    this.tim = TIM.create(options)
    // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
    this.tim.setLogLevel(6) // 普通级别，日志量较多，接入时建议使用
    // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
    let baseUrl = 'http://api.yulindata.net/'
    let chatContent = this.$refs.chatContent

    axios
      .get('http://api.yulindata.net/api/IM/GetServiceSeat?IsSeat=0')
      .then((res) => {
        let { data } = res
        if (data.IsSuccess) {
          this.serviceId = data.Entities[0].To
          let promise = this.tim.login({
            userID: data.Entities[0].From.toString(),
            userSig: data.Entities[0].UserSig
          })

          promise
            .then(function (imResponse) {
              if (imResponse.data.repeatLogin === true) {
                // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                console.log(imResponse.data.errorInfo)
              }
            })
            .catch(function (imError) {
              console.warn('login error:', imError) // 登录失败的相关信息
            })

          let _this = this

          let onMessageReceived = function (event) {
            // event.data - 存储 Message 对象的数组 - [Message]
            _this.messageList.push(event.data[0])

            _this.scrolltest()

            let promise = tim.setMessageRead({
              conversationID: event.data[0].conversationID
            })
            promise
              .then(function (imResponse) {
                // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
              })
              .catch(function (imError) {
                // 已读上报失败
                console.warn('setMessageRead error:', imError)
              })
          }

          _this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived)

          _this.tim.on(TIM.EVENT.SDK_READY, function (event) {
            // 打开某个会话时，第一次拉取消息列表
            let promise = _this.tim.getMessageList({
              conversationID: `C2C${_this.serviceId}`,
              count: 15
            })
            promise.then(function (imResponse) {
              const messageList = imResponse.data.messageList // 消息列表。

              _this.messageList = messageList

              if (messageList.length !== 0) {
                let promise = _this.tim.setMessageRead({
                  conversationID: messageList[0].conversationID
                })
                promise
                  .then(function (imResponse) {
                    // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
                  })
                  .catch(function (imError) {
                    // 已读上报失败
                    console.warn('setMessageRead error:', imError)
                  })
              }

              _this.nextReqMessageID = imResponse.data.nextReqMessageID // 用于续拉，分页续拉时需传入该字段。
              _this.isCompleted = imResponse.data.isCompleted // 表示是否已经拉完所有消息。
              _this.scrolltest()
            })
          })
        } else {
          window.alert(data.Message)
        }
      })
      .catch((error) => {})
  },
  methods: {
    // 发送
    sendMsg() {
      let _this = this
      if (this.content !== '') {
        // 1. 创建消息实例，接口返回的实例可以上屏
        let message = _this.tim.createTextMessage({
          to: _this.serviceId,
          conversationType: TIM.TYPES.CONV_C2C,
          payload: {
            text: _this.content
          }
        })

        // 2. 发送消息
        let promise = _this.tim.sendMessage(message)

        promise
          .then(function (imResponse) {
            let datasend = []
            datasend.push({
              MsgId: imResponse.data.message.ID,
              IMFrom: imResponse.data.message.from,
              IMTo: imResponse.data.message.to,
              MsgContent: imResponse.data.message.payload.text,
              TimeStamp: imResponse.data.message.time
            })

            _this.messageList.push(imResponse.data.message)

            _this.scrolltest()

            // 清空
            _this.content = ''
          })
          .catch(function (imError) {
            // 发送失败
            console.warn('sendMessage error:', imError)
          })
      } else {
        alert('请输入内容')
      }
    },

    // 加载更多
    load_more() {
      let _this = this
      let scrollHeight = this.$refs.chatContent.scrollHeight
      console.log(scrollHeight)

      // 打开某个会话时，第一次拉取消息列表
      let promise = _this.tim.getMessageList({
        conversationID: `C2C${_this.serviceId}`,
        count: 15,
        nextReqMessageID: _this.nextReqMessageID
      })
      promise.then(function (imResponse) {
        _this.messageList.unshift(...imResponse.data.messageList) // 消息列表。

        _this.$nextTick(() => {
          _this.$refs.chatContent.scrollTop =
            _this.$refs.chatContent.scrollHeight - scrollHeight
        })

        _this.nextReqMessageID = imResponse.datanextReqMessageID // 用于续拉，分页续拉时需传入该字段。
        _this.is_load_more = imResponse.data.isCompleted // 表示是否已经拉完所有消息。
        if (_this.is_load_more) _this.is_load_more = false
      })
      //   _this.scrolltest()
    },

    // 自动置底
    scrolltest() {
      this.$nextTick(() => {
        var div = this.$refs.chatContent
        if (div && div.scrollHeight) {
          div.scrollTop = div.scrollHeight
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.chat-content {
  height: 250px;
  background: #fafafa;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  padding-top: 21px;
  .load_more {
    font-size: 10px;
    color: #9b9b9b;
    text-align: center;
    cursor: pointer;
  }
  .other-msg {
    text-align: left;
    padding: 0 10px;
    margin-top: 10px;
    animation: show_left 0.5s;
    .service_title {
      font-size: 12px;
      margin-bottom: 6px;
      font-weight: 400;
      color: #9b9b9b;
    }
    span {
      display: inline-block;
      padding: 15px 20px 15px 15px;
      background-color: #e4e4e4;
      max-width: 100%;
      white-space: pre-wrap;
      white-space: pre-line;
      word-break: break-word;
      text-align: left;
      color: #525252;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      -webkit-animation: slide-right 0.2s ease-in-out;
      animation: slide-right 0.2s ease-in-out;
      font-size: 14px;
    }
  }

  .self-msg {
    text-align: right;
    padding: 0 10px;
    margin-top: 10px;
    animation: show_right 0.5s;
    span {
      display: inline-block;
      padding: 15px 20px 15px 15px;
      background-color: #4592e2;
      max-width: 100%;
      white-space: pre-wrap;
      white-space: pre-line;
      word-break: break-word;
      text-align: left;
      color: #ffffff;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      -webkit-animation: slide-left 0.2s ease-in-out;
      animation: slide-left 0.2s ease-in-out;
      font-size: 14px;
    }
  }

  @keyframes show_right {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes show_left {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }
}

.chat_input {
  height: 100px;
  background: #fff;
  border: 1px solid #d6d6d6;
  position: relative;
  #chat_area {
    border: none;
    outline: none;
    font-size: 14px;
  }
  .chatt_send_area {
    width: 56px;
    height: 30px;
    background: #1740df;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
  }
}
</style>